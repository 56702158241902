/*
@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;:where
  }
}

body {
  color: rgb(var(--foreground-rgb));
  background: linear-gradient(
      to bottom,
      transparent,
      rgb(var(--background-end-rgb))
    )
    rgb(var(--background-start-rgb));
}
*/

* {
  margin: 0;
  padding: 0;
}

html,
body {
  height: 100%;
}

a {
  /*all: unset;*/
  text-decoration: none;
  color: inherit;
}

button {
  all: unset;
}

/******************************************************/
/************* Systemwide Variables Below *************/
/******************************************************/
:root {
  --buttonBackgroundEnabled: #008489;
  --buttonBackgroundEnabledHover: #0084895b;
  --buttonColorEnabled: #ffffff;
  --buttonColorEnabledHover: #ffffff4d;
  --buttonBackgroundDisabled: #406480;
  --buttonColorDisabled: black;
  --buttonOpacityDisabled: 0.75;
  --buttonBackgroundnew: #318156;
  --buttonBackgroundHoverNew: #c5dbdb;

  --buttonColorExternal: #6435c9;
  --buttonColorExternalHover: #e0d6f4;
  --buttonLabelColor: #65676b;

  --backgroundLightBlue: #eff8ff;
  --backgroundGrayBlue: #e1eaf4;
  --backgroundLightPurple: #ececfe;
  --tagColorBlue: #3470d7;

  --buttonBackgroundPrimary: #318156;
  --buttonColorPrimary: white;
  --buttonBackgroundPrimaryHover: #318156c9;

  --buttonBackgroundSecondary: #ffffff00;
  --buttonColorSecondary: rgb(0, 0, 0);
  --buttonBackgroundSecondaryHover: rgb(235, 235, 235);
  --buttonBorderSecondary: rgb(176, 176, 176);

  --tagActivePrimary: #328157;
  --tagActiveSecondary: #ecf8f2;
  --btnFollowPrimary: #a9ddc1;
  --btnFollowTextPrimary: #328157;

  --cardBackground: #ffffff;

  /* A ButtonText is an HTML button element that looks like text. */
  --buttonTextLabelColor: #0099ff;

  --colorScrollbar: #82cda5;
  --colorScrollbarHover: #6eb891;

  /*  --hoverFilterBrightness: 0.7;*/

  --padXXSmall: 0.125rem;
  --padXSmall: 0.25rem;
  --padSmall: 0.5rem;
  --padMedium: 1rem;
  --padLarge: 1.25rem;
  --padXLarge: 1.5rem;
  --padXXLarge: 2rem;

  --sizingXXS: 0.125rem;
  --sizingXS: 0.25rem;
  --sizingS: 0.5rem;
  --sizingM: 1rem;
  --sizingL: 1.25rem;
  --sizingXL: 1.5rem;
  --sizingXXL: 2rem;
  --sizing75: 0.75rem;

  --borderPrimary: #d0d5dd;

  --borderRadiusSmall: 0.25rem;
  --borderRadiusMedium: 0.5rem;
  --borderRadiusLarge: 1rem;
  --borderRadiusHalf: 50%;

  /* Radius from Figma */
  --radiusL: 0.5rem;
  --radiusXL: 0.75rem;

  --borderRadiusButton: 0.35rem;
  --borderRadiusInput: 0.5rem;
  --borderRadiusContainer: 1rem;
  --borderColorDarkGray: rgba(169, 169, 169, 0.7);
  --borderColorLightBlue: #a8d2fdfb;

  --boxShadowBig: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
    rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
  --boxShadowClickable: 0 0 0 1px var(--colorGray235);
  --boxShadowBigClickable: var(--boxShadowBig), var(--boxShadowClickable);
  --boxShadowSelected: 0 0 0 3px var(--buttonColor);
  --boxShadowButtonSelected: 0 0 0 4px black inset;

  --boxShadowInput: inset 0 0 0 2px var(--colorGray34);
  --boxShadowInputInvalid: inset 0 0 0 2px var(--colorInvalid);

  --fontcolorPrimary: #000000; /* 100% - Black No use atm  */
  --fontColorPrimary90: rgba(0, 0, 0, 0.9); /* 90% - Titles */
  --fontColorPrimary80: rgba(0, 0, 0, 0.8); /* 80% - Border */
  /* 70% - Default location test color */
  --fontColorPrimary70: rgba(0, 0, 0, 0.7);
  /* 50% - Placeholder texts and button default */
  --fontColorPrimary50: rgba(0, 0, 0, 0.5);
  --fontColorPrimary10: rgba(0, 0, 0, 0.1); /* 10% - Disabled button */

  /* Name on the cards, Filter tags */
  --fontColorSecondary10: rgba(52, 64, 84, 1);

  --fontColorWhite: #ffffff;
  --fontColorWhite60: rgba(255, 255, 255, 0.6);

  --colorGray34: rgb(34, 34, 34); /* #222 */
  --colorGray113: rgb(113, 113, 113);
  --colorGray176: rgb(176, 176, 176);
  --colorGray235: rgb(235, 235, 235);
  --colorGray247: rgb(246, 247, 248);

  --colorPrimary: #318156; /* Menu Bar, CTA button background, primaryButtonText color  */
  --colorSecondary: #6fa689; /* Search for places, guides text box bg in figma  */
  --colorRed: #d92d20;
  --colorInvalid: firebrick;
  --backgroundInvalid: lightcoral;
  --colorValid: seagreen;
  --colorTab: #3ea56e;
  --colorPurple: #2c2eb1;

  --fadedTextColor: rgba(0, 0, 0, 0.4);
  --fadedBorderColor: lightgrey;

  --backgroundPrimary: #ededf5; /* From Figma */

  /* --scrollbarWidth: 14px; */

  --fontSizeHeaderTitle: 1.5rem;
  --fontSizeIcon: 1.3em;
  --fontSizeSubTitle: 1.25rem;
  --fontSizeSCTitle: 1rem;
  --fontSizeSCLabel: 1rem;
  --fontSizeSCValue: 0.875rem;
  --fontSizeSCDescription: 0.75rem;
  --fontWeightSCTitle: 400;
  --fontWeightTitleMedium: 500;

  --avatarImageSize: 3.5rem;
  --avatarImageSizeMedium: 2.5rem;
  --avatarImageSizeSmall: 2rem;

  --sidePanelWidth: 550px;

  --scrollbarWidth: 8px;
  --scrollbarThinWidth: 6px;
  --scrollbarThumbColor: rgba(0, 0, 0, 0.2);
  --scrollbarThumbHoverColor: rgba(0, 0, 0, 0.4);
  --scrollbarThumbRadius: 4px;

  --modalMaxWidth: 50rem;
}
/******************************************************/
/************* Systemwide Variables Above *************/
/******************************************************/

/*
.map-container {
  height: 100%;
  width: 100%;
  /*
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  */
/*
}
*/

/* Stop Google Maps from overriding our font. */
.gm-style {
  font: inherit !important;
}

/* TODO: Move this into a module.css file? */
img[src$="#myMapMarker"] {
  border: 1px solid black !important;
  border-radius: 50%;
}

.panelTitle {
  text-align: center;
  font-size: 1.5rem;
  font-weight: 400;
}

/*
.textEllipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
*/

/*
*::-webkit-scrollbar {
  display: none; /* Safari and Chrome */ /*
}
*:hover::-webkit-scrollbar {
  display: initial; /* Safari and Chrome */ /*
}
*::-webkit-scrollbar {
  -ms-overflow-style: none; /* Internet Explorer 10+ */ /*
  scrollbar-width: none; /* Firefox */ /*
}
*/

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

table {
  width: 100%;
  font-weight: 700;
  font-size: 1.1rem;
}
tr:nth-child(even) {
  background: whitesmoke;
}
tr {
  padding: 0.5rem;
  display: grid;
  grid-template-columns: auto auto;
  justify-items: right;
  align-items: center;
}
td:first-child {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  justify-content: left;
}
td:nth-child(2) {
  display: grid;
  grid-template-columns: auto auto;
  justify-items: left;
}

.expandedMarkerOverlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  pointer-events: none;
}

textarea {
  font: inherit;
}

/* Basic scrollbar settings */
/* Suitable for WebKit browsers (Chrome, Safari, Edge) */
::-webkit-scrollbar {
  width: var(--scrollbarWidth);
  height: var(--scrollbarWidth);
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: var(--scrollbarThumbColor);
  border-radius: var(--scrollbarThumbRadius);
}

::-webkit-scrollbar-thumb:hover {
  background-color: var(--scrollbarThumbHoverColor);
}

/* Suitable for WebKit corners */
::-webkit-scrollbar-corner {
  background: transparent;
}
